import app, { DEFAULT_APP_STATE } from "@/store/app";
import company, { DEFAULT_COMPANY_STATE } from "@/store/company";
import { createDirectStore } from "direct-vuex";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export enum ViewState {
  NOTHING = 0,
  COMPANY,
  APP,
  OTHER,
}

interface StoreState {
  view: ViewState;

  carousel: number;
  showSlides: boolean;
  costs: number;
}

const {
  store,
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext,
} = createDirectStore({
  state: {
    view: ViewState.NOTHING,
    carousel: 0,
    showSlides: true,
    costs: 0,
  } as StoreState,
  mutations: {
    setCarousel(state, value: number): void {
      state.carousel = value;
    },
    setCosts(state, value: number): void {
      state.costs = value;
    },
    setSlides(state, value: boolean): void {
      state.showSlides = value;
    },
    setState(state, view: ViewState): void {
      state.view = view;
    },
    resetState(state): void {
      state.view = ViewState.NOTHING;
      state.carousel = 0;
      state.showSlides = true;
      state.costs = 0;

      company.state = Object.assign(company.state, DEFAULT_COMPANY_STATE);
      app.state = Object.assign(app.state, DEFAULT_APP_STATE);
    },
  },
  actions: {},
  modules: {
    company,
    app,
  },
  strict: process.env.NODE_ENV !== "production",
});

// Export the direct-store instead of the classic Vuex store.
export default store;

// The following exports will be used to enable types in the
// implementation of actions and getters.
export {
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext,
};

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module "vuex" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    direct: AppStore;
  }
}
