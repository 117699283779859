import { setProperty } from "@/utils";

class Base {
  // General credential data
  credentials = false;
  user = "";
  password = "";
}

class App extends Base {
  // Android/iOS common base
  store = false;
  storeUrl = "";

  // Uses same credentials as web app
  webappCred = false;
}

export class iOSApp extends App {
  testflightUrl = "";
}

export class AndroidApp extends App {
  binary: File | null = null;
}

export class WebApp extends Base {
  // A web app
  url = "";
}

export class AppState {
  // Variants
  hasWebapp = true;
  hasAndroid = false;
  hasIos = false;
  // Data
  webapp: WebApp = new WebApp();
  android: AndroidApp = new AndroidApp();
  ios: iOSApp = new iOSApp();
}

export const DEFAULT_APP_STATE = new AppState();

function validApp(state: AppState, app: "ios" | "android"): boolean {
  if (
    (app == "ios" && !state.hasIos) ||
    (app == "android" && !state.hasAndroid)
  ) {
    return true;
  }

  const target = app == "ios" ? state.ios : state.android;

  // Require store link or testflight link/binary
  if (
    (target.store && target.storeUrl == "") ||
    (app == "ios" && !target.store && (target as iOSApp).testflightUrl == "") ||
    (app == "android" && !target.store && (target as AndroidApp).binary == null)
  ) {
    return false;
  }

  // No valid credentials (webapp credentials are required elsewhere)
  if (
    target.credentials &&
    !target.webappCred &&
    target.user == "" &&
    target.password == ""
  ) {
    return false;
  }

  return true;
}

export default {
  namespaced: true as const,
  actions: {},
  state: Object.assign({}, DEFAULT_APP_STATE),
  getters: {
    errorsWebapp: (state: AppState): Array<string> => {
      if (!state.hasWebapp) {
        return [];
      }

      const errors: Array<string> = [];

      if (state.webapp.url == "") {
        errors.push("URL fehlt");
      }
      if (
        state.webapp.credentials &&
        (state.webapp.user == "" || state.webapp.password == "")
      ) {
        errors.push("Nutzer/Passwort fehlt");
      }

      return errors;
    },
    errorsAndroid: (state: AppState): Array<string> => {
      if (!state.hasAndroid) {
        return [];
      }

      const errors: Array<string> = [];

      if (state.android.store && state.android.storeUrl == "") {
        errors.push('"Play Store"-Link fehlt');
      }
      if (!state.android.store && state.android.binary == null) {
        errors.push("App-Datei fehlt");
      }
      if (
        state.android.credentials &&
        !state.android.webappCred &&
        (state.android.user == "" || state.android.password == "")
      ) {
        errors.push("Nutzer/Passwort fehlt");
      }

      return errors;
    },
    errorsIos: (state: AppState): Array<string> => {
      if (!state.hasIos) {
        return [];
      }

      const errors: Array<string> = [];

      if (state.ios.store && state.ios.storeUrl == "") {
        errors.push('"App Store"-Link fehlt');
      }
      if (!state.ios.store && state.ios.testflightUrl == "") {
        errors.push("TestFlight-Link fehlt");
      }
      if (
        state.ios.credentials &&
        !state.ios.webappCred &&
        (state.ios.user == "" || state.ios.password == "")
      ) {
        errors.push("Nutzer/Passwort fehlt");
      }

      return errors;
    },
    validWebapp: (state: AppState): boolean => {
      if (!state.hasWebapp) {
        return true;
      }

      // Require URL and user/password if credentials are set
      return (
        state.webapp.url !== "" &&
        (!state.webapp.credentials ||
          (state.webapp.user !== "" && state.webapp.password !== ""))
      );
    },
    validAndroid: (state: AppState): boolean => {
      return validApp(state, "android");
    },
    validIos: (state: AppState): boolean => {
      return validApp(state, "ios");
    },
  },
  mutations: {
    setValue(
      state: AppState,
      payload: {
        name: keyof AppState;
        value: boolean | WebApp | AndroidApp | iOSApp;
      }
    ): void {
      setProperty(state, payload.name, payload.value);
    },
    setWebAppValue(
      state: AppState,
      payload: { name: keyof WebApp; value: boolean | string }
    ): void {
      // Workaround for some booleans being null instead of false
      let value = payload.value;
      if (typeof state.webapp[payload.name] === "boolean") {
        value = payload.value ?? false;
      }

      setProperty(state.webapp, payload.name, value);
    },
    setAppValue(
      state: AppState,
      payload: {
        name: keyof App | keyof AndroidApp | keyof iOSApp;
        value: boolean | string | File;
        version: "ios" | "android";
      }
    ): void {
      // Workaround for some booleans being null instead of false
      let value = payload.value;
      if (
        (payload.version == "ios" &&
          typeof state.ios[payload.name as keyof iOSApp] === "boolean") ||
        (payload.version == "android" &&
          typeof state.android[payload.name as keyof AndroidApp] === "boolean")
      ) {
        value = payload.value ?? false;
      }

      if (payload.version == "ios") {
        setProperty(
          state.ios,
          payload.name as keyof iOSApp,
          value as string | boolean
        );
      } else {
        setProperty(state.android, payload.name as keyof AndroidApp, value);
      }
    },
  },
};
