import { setProperty } from "@/utils";

export interface CompanyState {
  // Slide 1
  employees: number;
  sites: number;
  systems: number;
  // Slide 2
  internal: boolean;
  external: boolean;
  socialEngineering: boolean;
  // Slide 3
  name: string;
  email: string;
  street: string;
  city: string;
  employeeName: string;
  employeePhone: string;
}

export const DEFAULT_COMPANY_STATE = {
  employees: 50,
  sites: 1,
  systems: 0,
  internal: true,
  external: true,
  socialEngineering: true,
};

export default {
  namespaced: true as const,
  actions: {},
  state: Object.assign(
    {
      name: "",
      email: "",
      street: "",
      city: "",
      employeeName: "",
      employeePhone: "",
    },
    DEFAULT_COMPANY_STATE
  ),
  getters: {},
  mutations: {
    setValue(
      state: CompanyState,
      payload: { name: keyof CompanyState; value: boolean | number | string }
    ): void {
      setProperty(state, payload.name, payload.value);
    },
  },
};
