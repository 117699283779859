// From TypeScript docs
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-1.html
export function setProperty<T, K extends keyof T>(
  obj: T,
  key: K,
  value: T[K]
): void {
  if (typeof obj[key] == "number") {
    obj[key] = parseInt(value as unknown as string) as unknown as T[K];
    return;
  }
  obj[key] = value;
}

export function getProperty<T, K extends keyof T>(obj: T, key: K): T[K] {
  return obj[key]; // Inferred type is T[K]
}

export function makeFormData(
  formData: FormData,
  data: Record<string, string | null | File> | string | File | null,
  key: string
): void {
  if (data && typeof data === "object" && !(data instanceof File)) {
    Object.keys(data).forEach((_key: string) => {
      makeFormData(
        formData,
        (data as Record<string, string | null | File>)[_key],
        key !== "" ? `${key}[${_key}]` : _key
      );
    });
  } else {
    formData.append(key, data as string);
  }
}

// Magic hash used to suppress the locale modal after redirects
export const MAGIC_HASH = "#fixedlocale";

/**
 * Returns the ISO code of the current URL
 */
export function currentLocale(): "DE" | "AT" | "CH" {
  const env = process.env;

  if (location.host == env.VUE_APP_HOST_AT) {
    return "AT";
  }

  if (location.host == env.VUE_APP_HOST_CH) {
    return "CH";
  }

  return "DE";
}

/**
 * Redirects the user to the selected configured page
 * @param locale
 */
export function redirect(locale: "DE" | "AT" | "CH"): void {
  const env = process.env;
  location.href = `https://${env["VUE_APP_HOST_" + locale]}${MAGIC_HASH}`;
}
